<template>
  <v-container
    fill-height
    fluid
    class="my-6"
    :style="`width: ${$vuetify.breakpoint.xs ? '100%' : '50%'};`"
  >
    <v-stepper v-model="e1" class="mb-6" style="width: 100%;">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1">
          {{ $t('cart.chooseProduct') }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 2" step="2">
          {{ $t('cart.shipmentDetails') }}
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3">
          {{ $t('cart.payment') }}
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>
    <v-layout align-center justify-center>
      <v-flex xs12 sm12 md12>
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>{{
              $t('productOrder.voucherRecipientInfo')
            }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-text-field
              v-model="form.fullname"
              :label="$t('common.fullname')"
            ></v-text-field>
            <v-text-field
              v-model="form.phone"
              :label="$t('profile.phone')"
            ></v-text-field>
            <v-text-field
              v-model="form.contactEmail"
              :label="$t('common.email')"
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="justify-center align-center">
            <v-btn
              align-center
              justify-center
              color="warning"
              @click="onBack"
              >{{ $t("Quay lại") }}</v-btn
            >
            <v-btn
              align-center
              justify-center
              color="primary"
              @click="onUpdateUserProfile"
              >{{ $t("profile.btn.update") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,
      form: {
        fullname: "",
        contactEmail: "",
        phone: null,
      },
      e1: 2
    };
  },
  mounted() {
    this.GET_DELIVERY_INFO_DETAIL().then(
      function(res) {
        let data = res.data;
        this.form.fullname = data.fullname;
        this.form.phone = data.phone;
        this.form.contactEmail = data.email;
      }.bind(this)
    );
  },
  methods: {
    /**
     * Back
     */
    onBack: function () {
      window.history.back()
    },
    /**
     * Update user profile
     */
    onUpdateUserProfile: function() {
      // Todo
      let filter = {
        email: this.form.contactEmail,
        phone: this.form.phone,
        fullname: this.form.fullname
      };
      this.CREATE_DELIVERY_INFO(filter).then(
        function(res) {
          let data = res.data;
          let infoData = {
            email: this.form.contactEmail,
            phone: this.form.phone,
            fullname: this.form.fullname,
            id: data.id,
          };
          this.SET_SHIPMENT_DETAILS(infoData);
          this.$toast.open({
            message: this.$t("common.success"),
            type: "success",
            ...this.$toastConfig,
          });
          this.$router.push({
            name: "ProductCart",
          });
        }.bind(this)
      );
    },
    ...mapActions(["CREATE_DELIVERY_INFO", "GET_DELIVERY_INFO_DETAIL", "SET_SHIPMENT_DETAILS"]),
  },
};
</script>

<style></style>
